export const ENABLE_ORDERS = true;
export const CLIENT_APPLICATION_NAME = 'Remember Me'
export const APP_DESCRIPTION_P1 = `RememberMe is an AI application that not only interviews you but also becomes you—or, more accurately, a digital replica capable of interacting with others on your behalf. RememberMe uses advanced machine learning algorithms and natural language processing technologies to conduct in-depth interviews with users. Through these sessions, RememberMe gathers insights into the user's personality, preferences, speaking style, and thought processes.`

export const APP_DESCRIPTION_P2 = `The initial setup involves a the legacy user having an in depth interview with the RememberMe AI. This interview will include personal interests, life experiences, opinions on various subjects, and even reactions to hypothetical scenarios. The goal is to map out the user's unique cognitive and emotional landscape as accurately as possible. RememberMe uses this information to create a highly personalized AI model that reflects the user's way of thinking and communicating.`

// export const APP_DESCRIPTION_P3 = `Once the model is trained, EchoMe can serve multiple purposes. For one, it can act as a stand-in during online social interactions, mimicking the user's conversational style in chat applications or social media platforms. It could provide responses to messages when the user is unavailable, ensuring that no conversation is left hanging without straying from the user's typical way of engaging.`

// export const APP_DESCRIPTION_P4 = `Moreover, EchoMe could play a more profound role, such as offering advice or opinions based on how it predicts the user would respond. Imagine a friend interacting with EchoMe to get your take on a situation when you're unreachable. The AI could analyze the query, compare it with the user's past responses and behaviors, and generate advice or insights in the user's style.`

// export const APP_DESCRIPTION_P5 = `However, EchoMe's potential doesn't stop at mimicking for convenience or entertainment. It could serve as a digital legacy, preserving aspects of a person's thoughts and personality for future generations. Family and friends could interact with the AI to feel connected to a loved one who has passed away, experiencing their humor, wisdom, and character through conversations with the digital replica.`

// export const APP_DESCRIPTION_P6 = `The ethical implications and personal data security are critical considerations in developing such an AI. EchoMe would need to ensure the utmost privacy, consent, and security measures to protect users' identities and personal information. Nonetheless, if these challenges are addressed, EchoMe could revolutionize how we interact with technology, making AI not just a tool for efficiency but a bridge to deeper, more personal connections—even in our absence.`


export const DESCRIPTION_OF_GRANT_ACCESS_TO = "Choose who can access your legacy and digital replica. You can share your legacy with your loved ones, or keep it private until you are ready to share it."
export const DESCRIPTION_OF_YOU_HAVE_ACCESS_TO = "Your have family or friends that have shared their legacy with you. Click here to engage with their digital replica."