/* eslint-disable no-param-reassign */
import _, { isObject } from "lodash";
import { IReduceAction } from "../../interfaces/store";
import { IMainState } from "../../interfaces/store/main";
import { autoReduce } from "./shared";
import { normalize } from "normalizr";
import { roleSchema, userAccessorSchema, userRoleSchema, userSchema } from "../sagas/schema";
import { IUser, IUserExtended } from "../../shared/interfaces/model/user.interface";
import { IRole } from "../../shared/interfaces/model/role.interface";
import { IUserRole } from "../../shared/interfaces/model/user_role.interface";
import { ACTIONS } from "../../enums/actions";
import { IResource } from "../../shared/interfaces/model/resource.interfaces";
import { IGlobalSettings } from "../../shared/interfaces/global-settings.interface";
import { ITheme, THEME_NAME, getThemeColors } from "../../shared/constants/layout-constants";
import { IInteraction } from "../../shared/interfaces/model/interaction.interface";
import { ILifeData } from "../../shared/interfaces/model/life-data.interface";
import { IThread } from "../../shared/interfaces/model/thread.interface";
import { IUserAccessor } from "../../shared/interfaces/model/user_accessor.interface";

let setUser = {} as any;
let setUserRoles = {} as any;
let setRoles = {} as any;
let setGlobalSettings = {} as any;
let existingUser = localStorage.getItem("my_user");
let existingRoles = localStorage.getItem("roles");
let existingUserRoles = localStorage.getItem("user_roles")
let existingTimezone = localStorage.getItem("timezone");
let existingGlobalSettings = localStorage.getItem("global_settings");

if (!existingTimezone) {
  localStorage.setItem("timezone", "America/Chicago")
  existingTimezone = "America/Chicago"
}

if (existingUser)
  try {
    existingUser = JSON.parse(existingUser);
    setUser = existingUser;
  } catch (e) { }

if (existingUserRoles) {
  try {
    existingUserRoles = JSON.parse(existingUserRoles)
    setUserRoles = existingUserRoles
  }
  catch (e) { }
}

if (existingRoles) {
  try {
    existingRoles = JSON.parse(existingRoles)
    setRoles = existingRoles
  }
  catch (e) { }
}

if (existingGlobalSettings) {
try{
  existingGlobalSettings = JSON.parse(existingGlobalSettings);
  setGlobalSettings = existingGlobalSettings;
}catch{}
}

export const mainStateObject = {
  client_options: {},
  error: {} as any,
  global_settings: setGlobalSettings as IGlobalSettings,
  jwt_token: '',
  meta: {} as any,
  my_user: setUser as IUserExtended,
  user_accessors: {} as { [key: string]: IUserAccessor },
  resources: {} as { [key: string ]: IResource },
  roles: setRoles as { [key: string]: IRole },
  interactions: {} as { [key: string ]: IInteraction },
  life_datas: {} as { [key: string ]: ILifeData },
  threads: {} as { [key: string ]: IThread },
  success: {} as any,
  timezone: existingTimezone as string,
  user_roles: setUserRoles as { [key: string]: IUserRole },
  users: {} as { [key: string]: IUserExtended },
  loading: false,
  streams: {
    assistant_extraction: {
      text: ''
    },
    assistant_discussion: {
      text: ''
    }
  },
  theme: {
    colors: getThemeColors(THEME_NAME.LIGHT),
    classes: {
      main: "light-theme",
    }
  } as ITheme,
};

const resetState = _.cloneDeep(mainStateObject)

const initialState: IMainState = { ...mainStateObject };

const mainReducer = (
  state: any = initialState,
  action: IReduceAction
): IMainState => {


  if (action && (action.stateKey === "main" || !action.stateKey)) {
    if (action.normalized || action.removeKeys || action.overrides) {
      return autoReduce(action, state);
    }

    switch (action.type) {
      case ACTIONS.ERROR:
        if (isObject(action.payload)) {
          state.error = action.payload;
          return { ...state };
        }
        break;
      case ACTIONS.SUCCESS:
        if (isObject(action.payload)) {
          state.success = action.payload;
          return { ...state };
        }
        break;
      case ACTIONS.IS_LOADING:
        state.loading = true;
        return { ...state };
      case ACTIONS.NOT_LOADING:
        state.loading = false;
        return { ...state };
      case "GET_USER_NOTE_SUCCESS":
        state.user_notes = action.payload;
        break;
      case ACTIONS.SYNC_DATA_FROM_SOCKET:
        const socketPackage = action.payload;
        if (action.payload.context === 'assistant_extraction') {
          let currentValue = state.streams.assistant_extraction;
          currentValue.text += socketPackage.text || "";
          const returnState = {
            ...state,
            streams: { 
              ...state.streams,
              assistant_extraction: currentValue
             },
          }
          return returnState;
        }
        if (action.payload.context === 'assistant_discussion') {
          let currentValue = state.streams.assistant_discussion;
          currentValue.text += socketPackage.text || "";
          const returnState = {
            ...state,
            streams: { 
              ...state.streams,
              assistant_discussion: currentValue
             },
          }
          return returnState;
        }
        return {...state}
      case ACTIONS.RESET_STREAM_ASSISTANT_EXTRACTION:
          const returnStateExtraction = {
            ...state,
            streams: { 
              ...state.streams,
              assistant_extraction: {
                text: ''
              }
              },
          }
          return returnStateExtraction;
      case ACTIONS.RESET_STREAM_ASSISTANT_DISCUSSION:
          const returnStateDiscussion = {
            ...state,
            streams: { 
              ...state.streams,
              assistant_discussion: {
                text: ''
              }
              },
          }
          return returnStateDiscussion;
      case ACTIONS.LOGIN_SUCCESS:
        const roles = normalize(action.data.roles, [roleSchema])?.entities?.roles || {}
        const user_roles = normalize(action.data.user_roles, [userRoleSchema])?.entities?.user_roles || {}
        const users = normalize(action.data.users, [userSchema])?.entities?.users || {}
        const user_accessors = normalize(action.data.user_accessors, [userAccessorSchema])?.entities?.user_accessors || {}
        const global_settings = action.data.global_settings
        localStorage.setItem("jwt", action.data.token);
        localStorage.setItem(
          "my_user",
          JSON.stringify(action.data.user)
        );
        localStorage.setItem(
          "user_roles",
          JSON.stringify(user_roles)
        );
        localStorage.setItem(
          "roles",
          JSON.stringify(roles)
        );
        localStorage.setItem(
          "global_settings",
          JSON.stringify(global_settings)
        );

        state.my_user = action.data.user;
        state.user_roles = user_roles
        state.jwt_token = action.data.token;
        state.roles = roles;
        state.users = users;
        state.user_accessors = user_accessors;
        return { ...state }
      case "LOGOUT_SUCCESS":
        localStorage.removeItem("jwt");
        localStorage.removeItem("my_user");
        localStorage.removeItem("user_roles");
        localStorage.removeItem("roles");
        state.jwt_token = "";
        // eslint-disable-next-line no-restricted-globals
        window.location.href = "/login";
        return { ...resetState }
      case "SET_CLIENT_OPTIONS_SUCCESS":
        state.client_options = {
          ...state.client_options,
          ...action.payload,
        };
        break;
      case "UPDATE_TIMEZONE":
        state.timezone = action.payload
        break;
      default:
        return state;
    }
  }
  return state;
};

export default mainReducer;
