
import { useState } from 'react';
import { Tabs, Tab, Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Button, Select, InputLabel, MenuItem, Grid } from '@mui/material';
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { MARGIN_LARGE, MARGIN_MEDIUM, THEME_NAME } from '../../shared/constants/layout-constants';
import { TabPanel } from '../general/TabShared';
import { updateGlobalSettings } from '../../store/actions/globalSettingsActions';
import { updateAIAssistant } from '../../store/actions/interviewActions';
import { useTranslate } from '../../store/translations';

function GlobalSettings(props: any) {


    const [selectedTab, setSelectedTab] = useState(0);
    const translate = useTranslate();
    const globalSettings = useSelector((state: any) => state.main.global_settings);
    const theme = useSelector((state: any) => state.main.theme);
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            theme: globalSettings.theme,
            language: globalSettings.language
        },
        validationSchema: Yup.object().shape({
            theme: Yup.string().required("Required"),
        }),
        validate: (values: any) => {
            const errors = {} as any;
            return errors;
        },
        onSubmit: (values) => {
            alert(JSON.stringify(values, null, 2));
        },
    });

    return (
        <div>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={selectedTab}
                    onChange={(event: React.SyntheticEvent, newValue: number) => {
                        setSelectedTab(newValue as any);
                    }}
                    aria-label="Global Settings"
                >
                    <Tab label={translate('General')} />
                    {/* <Tab label="Other" /> */}
                </Tabs>
            </Box>






            <TabPanel value={selectedTab as any} index={0}>


                <Grid container spacing={2} style={{ textAlign: 'left', paddingLeft: 16, paddingRight: 16, paddingTop: 16 }} direction="row">

                    <Grid item xs={12} mt={1}>
                        <FormControl>
                            <FormLabel style={{ color: theme.colors.font }}><b>{translate('Theme Settings')}</b></FormLabel>
                            <RadioGroup name="theme" value={formik?.values?.theme}>
                                <FormControlLabel
                                    key={0}
                                    value={THEME_NAME.LIGHT}
                                    defaultChecked={formik?.values?.theme === THEME_NAME.LIGHT}
                                    control={<Radio style={{ color: theme.colors.accent_primary }} onClick={() => {
                                        formik.setFieldValue("theme", THEME_NAME.LIGHT);
                                    }} />}
                                    label={"Light"}
                                />
                                <FormControlLabel
                                    key={1}
                                    value={THEME_NAME.DARK}
                                    defaultChecked={formik?.values?.theme === THEME_NAME.DARK}
                                    control={<Radio style={{ color: theme.colors.accent_primary }} onClick={() => {
                                        formik.setFieldValue("theme", THEME_NAME.DARK);
                                    }} />}
                                    label={"Dark"}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} mt={1}>
                        {/* Language Selection */}
                        <FormControl>
                            <InputLabel id="language-select-label">Language</InputLabel>
                            <Select
                                labelId="language-select-label"
                                id="language-select"
                                value={formik.values.language || ''}
                                label="Language"
                                onChange={(event) => formik.setFieldValue('language', event.target.value)}
                                style={{ color: theme.colors.font, marginBottom: MARGIN_MEDIUM }}
                            >
                                <MenuItem value="en">English</MenuItem>
                                <MenuItem value="es">Spanish</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} mt={1}>
                    <Button style={{ marginTop: MARGIN_MEDIUM }} variant={'outlined'} onClick={() => {
                        dispatch(updateGlobalSettings({
                            theme: formik?.values?.theme,
                            language: formik?.values?.language
                        }, () => { }, () => { },
                            "Global Settings Updated", true, true));
                    }}>
                        {translate('Save')}
                    </Button>
                </Grid>

                <Grid item xs={12} mt={1}>
                    <Button style={{ marginTop: MARGIN_MEDIUM }} variant={'outlined'} onClick={() => {
                        dispatch(updateAIAssistant({}, () => { }, () => { },
                            "AI Updated", true, true));
                    }}>
                        {translate('Update AI')}
                    </Button>
                </Grid>
                </Grid>




            </TabPanel>
        </div>


    )
}

export default GlobalSettings