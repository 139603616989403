import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../interfaces/store';
import { getUserStats } from '../../../store/actions/userActions';
import Loading from '../../../components/general/Loading';
import { COLORS } from '../../../shared/constants/layout-constants';

function UserViewPage(props: any) {
  const params = useParams<{ userId: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState({} as any);
  const [userFetched, setUserFetched] = useState(false);
  const [fetchedUserData, setFetchedUserData] = useState([] as any);

  const [loading, setLoading] = useState(true);
	const mincynotess_updated = useSelector((state:any) => state.main.meta?.mincy_notes?.updated_at || 0);

  useEffect(() => {
    if (!isNaN(Number(params.userId))) {
      dispatch(getUserStats({ user_id: params.userId }, (data) => {
        setFetchedUserData(data.roles); setUserFetched(true)
      }));
    }
  }, []);
  const users = useSelector((state: IRootState) => state.main.users);


  useEffect(() => {
    if (userFetched) {
      const matchedUser = Object.values(users).find((x) => x.id === Number(params.userId)) as any;

      if (!matchedUser) {
        navigate('/Users');
      } else {
        setUser(matchedUser);
        setLoading(false);
      }
    }
  }, [users, userFetched]);

  <Loading loading={loading} />;

  if (loading) {
    return <div></div>;
  }
  
  return (
    <>
      {user && <Box
        sx={{
          display: 'flex',
          // justifyContent: 'space-between',
        }}
      >
        {/* <b>User:&nbsp;</b>{user?.first_name +' '+user?.last_name} */}

        {user && <pre
							style={{
								backgroundColor: 'lightgrey',
								color: 'black',
								whiteSpace: 'pre-wrap',
								padding: '10px',
								borderRadius: '5px',
								fontFamily: 'monospace',
								overflowX: 'auto'
							}}
						>
							{JSON.stringify(user, null, 2)}
						</pre>}

      </Box>}


    </>
  );
}

export default UserViewPage;
