import * as React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../../store';
import { useTranslate } from '../../../store/translations';
import { refreshUserData } from '../../../store/actions/authActions';
import { getMyLegacyAccessUsers } from '../../../store/hooks/get-user-accessors';
import { useNavigate } from 'react-router-dom';
import AvatarCircle from '../../../components/shared/avatar-circle';
import { getS3URL } from '../../../utils/resource';
import { DESCRIPTION_OF_YOU_HAVE_ACCESS_TO } from '../../../shared/constants/app-constants';


function AccessLegaciesPage() {

  const dispatch = useDispatch();
  const translate = useTranslate();
  const navigate = useNavigate();
  const resources = useSelector((state) => Object.values(state.main.resources));
	const { myLegacyAccessUsers } = getMyLegacyAccessUsers();

  React.useEffect(() => { 
    dispatch(refreshUserData({}))
  }, [])

  return (
    <>
      <h2 style={{marginTop: 0, paddingTop: 0}}>{translate("Talk with Legacy")}</h2>

      <p>{translate(DESCRIPTION_OF_YOU_HAVE_ACCESS_TO)}</p>
      <Grid container spacing={2}>
      {myLegacyAccessUsers.map((myLegacyAccessUser, index) => {
        const profileUrl = getS3URL(resources, myLegacyAccessUser?.user?.id || -1, 'profile', 'thumb', false, false);

        return (
        <Grid style={{cursor: 'pointer'}} item xs={12} key={index}>
             <Card variant="outlined" sx={{
              '&:hover': {
                backgroundColor: '#f5f5f5', // Customize your hover color here
              },
            }}
            onClick={() => navigate('/AccessLegacy/' + myLegacyAccessUser?.user?.id)}
            >
            <CardContent>
              <AvatarCircle signed_avatar_url={profileUrl} size={100} />
              <Typography variant="h5" component="h2">
                {myLegacyAccessUser?.user?.first_name} {myLegacyAccessUser?.user?.last_name}
              </Typography>
              <Typography color="textSecondary">
                Legacy User
              </Typography>
              <Typography variant="body2" component="p">
                This user has granted you access to interact with their digital legacy.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        )
})}
    </Grid>
    </>

  );
}

export default AccessLegaciesPage;