import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getInteractionHistory } from '../../../store/actions/interactionActions';
import { useSelector } from '../../../store';
import { IRootState } from '../../../interfaces/store';
import { nextInterviewPrompt, resetAssistantExtraction } from '../../../store/actions/interviewActions';
import ChatComponent from '../../../components/chat/ChatComponent';
import { useTranslate } from '../../../store/translations';
import AvatarCircle from '../../../components/shared/avatar-circle';
// import { Translator } from '../../../shared/constants/translations';

function InterviewPage(props: any) {

  const globalSettings = useSelector((state) => state.main.global_settings);
  const translate = useTranslate()

  const [speechToText, setSpeechToText] = useState('');
  const [waitingForMessage, setWaitingForMessage] = useState(false);
  const [tempUserMessage, setTempUserMessage] = useState('');
  const [fetchedInteractionHistory, setFetchedInteractionHistory] = useState(false);
  const dispatch = useDispatch();
  const interactions = useSelector((state: IRootState) => state.main.interactions);
  const assistantExtractionText = useSelector((state: IRootState) => state.main.streams.assistant_extraction.text);

  const myUser = useSelector((state: IRootState) => state.main.my_user);
  const lifeDatas = useSelector((state: IRootState) => state.main.life_datas);
  const userLifeData = Object.values(lifeDatas).find((x: any) => x.user_id === myUser?.id);
  const threads = useSelector((state: IRootState) => state.main.threads);
  const relevantThread = Object.values(threads).find((x: any) => x.life_data_id === userLifeData?.id && x.context === 'interview');

  let threadInteractions = Object.values(interactions).filter((x: any) => x.thread_id === relevantThread?.thread_id);
  threadInteractions = threadInteractions.sort((a: any, b: any) => a.created_at - b.created_at).splice(-20);

  useEffect(() => { 
    if (fetchedInteractionHistory && relevantThread && threadInteractions.length === 0) {
      handleSendMessage(translate('Lets start this interview!'))
    }
  }, [fetchedInteractionHistory])
  
  const handleSendMessage = (newMessage: string) => {

    dispatch(resetAssistantExtraction({}));

    setWaitingForMessage(true);
    if (relevantThread && userLifeData) {
      dispatch(nextInterviewPrompt({
        life_data_id: userLifeData.id,
        threadId: relevantThread.thread_id,
        userInput: newMessage
      }, (data: any) => {
        setWaitingForMessage(false);
      }, (failData) => {
        console.log("fail data", failData)
        setWaitingForMessage(false);

      }))
      setTempUserMessage(newMessage);
    }
  };

  useEffect(() => {
    dispatch(getInteractionHistory({
      context: 'interview',
      legacyUserId: myUser?.id
    }, (data) => {
      setFetchedInteractionHistory(true)
    }))
  }, [])

  return (
    <>
      <h2 style={{ marginTop: 0, paddingTop: 0 }}>{translate("Interview")}</h2>

      {fetchedInteractionHistory &&

        <>
            <ChatComponent
              interactions={threadInteractions}
              waitingForMessage={waitingForMessage}
              assistantText={assistantExtractionText}
              tempUserMessage={tempUserMessage}
              handleSendMessage={handleSendMessage}
              speechToText={speechToText}
            />
        </>
      }

      {/* <AudioRecorder 
    onSpeechToText={(text) => setSpeechToText(text)}
    /> */}
    </>

  );
}

export default InterviewPage;
