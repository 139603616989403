import { IConversation } from "../interfaces/model/conversation.interface";

export const languageOptions = {
  en: {
    name: "English",
    code: "en",
    prompt_adder: ""
  },
  es: {
    name: "Spanish",
    code: "es",
    prompt_adder: "The user prefers to speak in spanish, unless the users last input was in another language, your output / response should always be in spanish."
  },
}
export interface ICoreChatStreamed {
  text?: string;
  funcName?: string;
  funcArgs?: any;
}

export const getTopCommonAttributes = (goalResponses: any, attributeName: any, maxTopMatches = 2) => {
  if (!Array.isArray(goalResponses) || goalResponses.length === 0) {
    return [];
  }

  // Count the occurrences of each attribute
  const attributeCounts = goalResponses.reduce((acc, response) => {
    const attrValue = response[attributeName];
    acc[attrValue] = (acc[attrValue] || 0) + 1;
    return acc;
  }, {});

  // Create an array from the attributeCounts object and sort it by frequency
  const sortedAttributes = Object.keys(attributeCounts)
    .map(attr => ({ attr, count: attributeCounts[attr] }))
    .sort((a, b) => b.count - a.count);

  // Take the top 'maxTopMatches' attributes
  const topAttributes = sortedAttributes.slice(0, maxTopMatches).map(entry => entry.attr);

  return topAttributes;
}


export function getRandomStringFromList(list: string[]) {
  const randomIndex = Math.floor(Math.random() * list.length);
  return list[randomIndex];
}

export enum RELATIONSHIP_TYPE {
  FRIEND = 'friend',
  MOTHER = 'mother',
  FATHER = 'father',
  SISTER = 'sister',
  BROTHER = 'brother',
  SON = 'son',
  DAUGHTER = 'daughter',
  GRANDMOTHER = 'grandmother',
  GRANDFATHER = 'grandfather',
  GRANDSON = 'grandson',
  GRANDDAUGHTER = 'granddaughter',
  AUNT = 'aunt',
  UNCLE = 'uncle',
  NIECE = 'niece',
  NEPHEW = 'nephew',
  COUSIN = 'cousin',
  SPOUSE = 'spouse',
  PARTNER = 'partner',
  BOYFRIEND = 'boyfriend',
  GIRLFRIEND = 'girlfriend',
  HUSBAND = 'husband',
  WIFE = 'wife',
  COLLEAGUE = 'colleague',
  CLASSMATE = 'classmate',
  OTHER = 'other',
}
