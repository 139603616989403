import React, { useEffect, useState } from 'react';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../shared/MaterialTableShared';
import Visibility from '@mui/icons-material/Visibility';
import { Button, TablePagination, Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import Loading from '../general/Loading';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import HttpClient from '../../api/HttpClient';
import { COLORS } from '../../shared/constants/layout-constants';
import { useSelector } from '../../store';
import { getUserAccessors } from '../../store/actions/userActions';
import { IUser } from '../../shared/interfaces/model/user.interface';
import GrantAccessModal from './GrantAccessModal';
import { AddCircleOutline, PreviewRounded } from '@mui/icons-material';
import AvatarCircle from '../shared/avatar-circle';
import { getS3URL } from '../../utils/resource';
import { useTranslate } from '../../store/translations';

const client = new HttpClient();
interface IAccessorTable {
	title: string,
}

const iconStyle = {
	color: COLORS.BRAND_PRIMARY, fontSize: 24, cursor: 'pointer'
}


const grantAccessDefaultValues = {
	first_name: '',
	last_name: '',
	email: '',
	gender: 'male',
	accessor_relationship_to_user: 'friend'
}

const AccessorTable: React.FC<IAccessorTable> = (props) => {

	const translate = useTranslate();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const [processing, setProcessing] = useState(false);
	const [selectedUserData, setSelectedUserData] = useState({} as any)
    const resources = useSelector((state) => Object.values(state.main.resources));

	const [grantAccessModalOpen, setGrantAccessModalOpen] = useState(false);
	const [grantAccessInitialValues, setGrantAccessInitialValues] = useState({...grantAccessDefaultValues} as any);
	const [grantAccessModalKey, setGrantAccessModalKey] = useState(0);

	const myUser = useSelector((state) => state.main.my_user);
	const users = useSelector((state) => Object.values(state.main.users));
	const userAccessors = useSelector((state: any) => Object.values(state.main.user_accessors));
	const myUserAccessors = userAccessors.filter((x: any) => x.user_id == myUser.id && x.active == true);

	const combinedUserAndUserAccessorData = myUserAccessors.map((userAccessorX: any) => {
		const matchedUser = users.find((userY: IUser) => userY.id == userAccessorX.accessor_id);
		return {
			...userAccessorX,
			first_name: matchedUser?.first_name,
			last_name: matchedUser?.last_name,
			email: matchedUser?.email
		}
	})


	const [accessorTableKey, setAccessorTableKey] = useState(0);
	const theme = useSelector((state) => state.main.theme);
	const { title } = props;

	const mainStyle = {
		backgroundColor: theme.colors.background,
		color: theme.colors.font,
	}

	useEffect(() => { 
		getUserAccessorInfo();
	}, [])

	const getUserAccessorInfo = () => {
		dispatch(getUserAccessors({}, () => {
			setAccessorTableKey(accessorTableKey + 1)
		}))
	}

	const columns = [
		{
			title: translate('First Name'), field: 'first_name', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: translate('Last Name'), field: 'last_name', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: translate('Relationship'), field: 'accessor_relationship_to_user', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: translate('Email'), field: 'email', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: translate('Image'), field: 'email', sorting: false, cellStyle: {
				width: 300,
				maxWidth: 300
			},
			render: (row: any) => {
				const profileUrl = getS3URL(resources, row.accessor_id || -1, 'profile', 'thumb', false, false);
				return (
					<div style={{ cursor: 'pointer' }}>
						{/* {row.accessor_id} */}
						<Tooltip title={"View User"} placement="top">
						<AvatarCircle signed_avatar_url={profileUrl}/>
						</Tooltip>
					</div>
				);
			},
		}
		// {
		// 	title: translate("User Actions"),
		// 	render: (row: any) => {
		// 		return (
		// 			<div style={{ cursor: 'pointer' }}>
		// 				<Tooltip title={"View User"} placement="top">
		// 					<Visibility
		// 						style={iconStyle}
		// 						onClick={() => {
		// 							navigate('/Users/' + row.id)
		// 						}}
		// 					/>
		// 				</Tooltip>
		// 			</div>
		// 		);
		// 	},
		// }
	];

	return (
		<div>
			<Loading loading={processing} />
		<GrantAccessModal 
			key={grantAccessModalKey}
			setGrantAccessModalOpen={setGrantAccessModalOpen} 
			open={grantAccessModalOpen} 
			initialValues={grantAccessInitialValues} 
			onSuccess={() => {
				console.log("calls onsuccess")
				getUserAccessorInfo();
				setGrantAccessModalOpen(false);
			}} 
		/>
		<div style={{ width: '100%', marginBottom: 16, display: 'flex' }}>
			<div style={{ flex: 1, textAlign: 'left' }}>

				<Button style={{marginRight: 8}} onClick={() => {
					setGrantAccessModalKey(grantAccessModalKey + 1);
					setGrantAccessModalOpen(true);
					setGrantAccessInitialValues({...grantAccessDefaultValues})
				}} variant='outlined' startIcon={<AddCircleOutline />}>
					{translate("Give Access")}
				</Button>

				<Button onClick={() => {
					navigate('/AccessLegacy/' + myUser.id)
				}} variant='outlined' startIcon={<PreviewRounded />}>
					{translate("Preview Access")}
				</Button>
			</div>
		</div>

			{myUserAccessors.length > 0 && <div key={accessorTableKey}>
				<MaterialTable
					icons={tableIcons}
					title={title}
					columns={columns}
					style={mainStyle}
					data={combinedUserAndUserAccessorData}
					options={{
						sorting: true,
						search: true,
						pageSize: 15,
						pageSizeOptions: [
							25, 50, 100, 200
						],
						headerStyle: mainStyle,
						searchFieldStyle: mainStyle,

					}}
					components={{
						Pagination: props => (
							<TablePagination
								{...props}
								style={{ ...mainStyle, backgroundColor: 'grey' }}
							/>
						),
					}}
				/>
			</div>}
		</div>

	);
};

export default AccessorTable;
