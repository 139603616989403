import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { TextField, Box, Paper, Typography, IconButton } from '@mui/material';
import { ACTOR } from '../../shared/constants/openai-constants';
import SendIcon from '@mui/icons-material/Send';
import LoadingDots from '../../components/general/LoadingDots';
import { IInteraction } from '../../shared/interfaces/model/interaction.interface';


interface IChatComponent {
  interactions: IInteraction[],
  waitingForMessage: boolean,
  assistantText: string,
  tempUserMessage: string,
  speechToText?: string,
  handleSendMessage: (message: string) => void
}

function ChatComponent(props: IChatComponent) {

  const [renderPhase, setRenderPhase] = useState(0); // 0 = normal, 1 = waiting for cleanup, 2 = render new interactions

  const scrollRef = useRef(null) as any;
  const [newMessage, setNewMessage] = useState('');

  useEffect(() => {
    scrollRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [props.interactions.length]); // Depend on the length of userInteractions to trigger the scroll

  useEffect(() => {
    if (props.assistantText.length > 0) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [props.assistantText]); // Depend on the length of userInteractions to trigger the scroll


  useEffect(() => {
    if (props.tempUserMessage.length > 0) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [props.tempUserMessage]); // Depend on the length of userInteractions to trigger the scroll
  
  useEffect(() => {
    if (!props.waitingForMessage) {
      // Trigger the first phase of rendering when waitingForMessage becomes false
      setRenderPhase(1);
    }
  }, [props.waitingForMessage]);


  useEffect(() => {
    if (renderPhase === 1) {
      // After the first cleanup render, prepare to render new interactions
      requestAnimationFrame(() => {
        setRenderPhase(2);
      });
    } else if (renderPhase === 2) {
      // Scroll into view after new interactions are rendered
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
      setRenderPhase(0); // Reset the render phase
    }
  }, [renderPhase]);

  // useEffect(() => {
  //   if (props.speechToText && props.speechToText.length){
  //     setNewMessage(props.speechToText);
  //   }
  // }, [props.speechToText]); // Depend on the length of userInteractions to trigger the scroll

  const renderInteraction = (interaction: any, key: any) => {
    const modText = interaction.text.replace(/【[^】]*】/g, "");

    return (
      <Paper
        key={key}
        elevation={3}
        sx={{
          alignSelf: interaction.initiator === ACTOR.USER ? 'flex-end' : 'flex-start',
          bgcolor: interaction.initiator === ACTOR.USER ? '#0c3129' : '#e5e5ea',
          color: interaction.initiator === ACTOR.USER ? 'white' : 'black',
          p: 1,
          borderRadius: '20px',
          m: 1,
          maxWidth: '60%',
        }}
      >
        <Typography variant="body1">{modText}</Typography>
      </Paper>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '80vh' }}>
      <Box
        sx={{
          flexGrow: 1,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: { xs: 1, sm: 3 },
          backgroundColor: '#f0f0f0',
        }}
      >
        {props.interactions.map((interaction: any) => (
          renderInteraction(interaction, interaction.id)
        ))}
        {renderPhase < 2 && props.waitingForMessage && (
          <>
            {renderInteraction({
              initiator: ACTOR.USER,
              text: props.tempUserMessage,
              created_at: Date.now()
            }, 'temp-user-message')}
            {props.assistantText.length > 0 && renderInteraction({
              initiator: ACTOR.ASSISTANT,
              text: props.assistantText,
              created_at: Date.now()

            }, 'temp-assistant-message')}
            {props.assistantText.length === 0 && <><br /><br /><LoadingDots /></>}
          </>
        )}
        <div ref={scrollRef} />
      </Box>
      <Box
        component="form"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: { xs: 1, sm: 2 },
          borderTop: '1px solid #e0e0e0',
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          disabled={props.waitingForMessage}
          fullWidth
          variant="outlined"
          placeholder="Type a message..."
          multiline={true}
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              if (!props.waitingForMessage) {
                e.preventDefault();
                props.handleSendMessage(newMessage);
                setNewMessage('')
              }
            }
          }}
        />
        <IconButton
          disabled={props.waitingForMessage}
          color="primary"
          onClick={() => {
            if (!props.waitingForMessage) {
              props.handleSendMessage(newMessage)
              setNewMessage('')
            }
          }}
          sx={{ ml: 2 }}
        >
          <SendIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

export default ChatComponent;
