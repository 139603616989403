import { useSelector } from 'react-redux';
import { IRootState } from '../../interfaces/store';
import { IUserAccessor } from '../../shared/interfaces/model/user_accessor.interface';
import { IUser } from '../../shared/interfaces/model/user.interface';

export interface IUserAccessorData {
  user: IUser | undefined; // Assuming find() might return undefined if no user is found
  userAccessor: IUserAccessor;
}

// Custom hook to get users and their accessors
export function getMyLegacyAccessUsers(): { myLegacyAccessUsers: IUserAccessorData[] } {
  const users = useSelector((state: IRootState) => Object.values(state.main.users));
  const userAccessors = useSelector((state: IRootState) => Object.values(state.main.user_accessors));
  const userAccessorIds = userAccessors.map((x) => x.user_id);
  const myUserAccessors = userAccessors.filter((x) => userAccessorIds.includes(x.user_id));

  const getDataForUserAccessor = (userAccessor: IUserAccessor) => {
    return {
      user: users.find((x) => x.id === userAccessor.user_id),
      userAccessor: userAccessor,
    };
  }

  const myLegacyAccessUsers = myUserAccessors.map((userAccessor) => getDataForUserAccessor(userAccessor));


  return { myLegacyAccessUsers };
}