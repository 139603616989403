import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { MARGIN_SMALL } from '../../shared/constants/layout-constants';
import { grantUserAccess } from '../../store/actions/userActions';
import { validateEmail } from '../../utils/validator';
import CustomAlert from '../general/CustomAlert';
import StandardModal from '../shared/StandardModal';
import { useTranslate } from '../../store/translations';
import { RELATIONSHIP_TYPE } from '../../shared/constants/conversation.constants';

interface IGrantAccessModal {
  setGrantAccessModalOpen: (dir: boolean) => void;
  open: boolean;
  initialValues: any;
  onSuccess: () => void;
}

const GrantAccessModal: React.FC<IGrantAccessModal> = (props) => {

  const translate = useTranslate();
  const validate = (values: any) => {
    const errors = {} as any;

    if (!values.email) {
      errors.email = 'Required';
    }
    if (values.email && !validateEmail(values.email)) {
      errors.email = 'Invalid Email';
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: props.initialValues,
    validate,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const dispatch = useDispatch();

  const closeModal = () => {
    props.setGrantAccessModalOpen(false);
  };

  const handleGrantAccessCall = () => {
    dispatch(
      grantUserAccess(
        { ...formik.values },
        () => {
          closeModal();
          if (props.onSuccess) {
            props.onSuccess();
          }
        },
        () => {
        }, 'User updated successfully', true, true
      ),
    );
  };

  return (
    <>
      <StandardModal
        title={translate('Give Access')}
        open={props.open}
        onClose={() => props.setGrantAccessModalOpen(false)}
        actions={[
          {
            title: `${translate('Yes')}`,
            callback: () => {
              formik.validateForm(formik.values).then((errors) => {
                if (Object.keys(errors).length === 0) {
                  handleGrantAccessCall();
                }
              });
            },
          }
        ]}
      >
        <>
          <div style={{ display: 'block' }}>
            {Object.keys(formik.errors).length > 0 ? (
              <CustomAlert
                severity="error"
                title="Please verify fields below"
                message={
                  'Marked input fields are required.'
                }
              />
            ) : null}
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    error={!!formik.errors.first_name}
                    label={translate("First Name")}
                    fullWidth={true}
                    size="small"
                    name="first_name"
                    onChange={formik.handleChange}
                    style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                    value={formik.values.first_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={!!formik.errors.last_name}
                    label={translate("Last Name")}
                    fullWidth={true}
                    size="small"
                    name="last_name"
                    onChange={formik.handleChange}
                    style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                    value={formik.values.last_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={!!formik.errors.email}
                    label={translate("Email")}
                    fullWidth={true}
                    size="small"
                    name="email"
                    onChange={formik.handleChange}
                    style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                    value={formik.values.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                  <FormControl fullWidth variant="outlined" size="small" error={formik.touched.gender && !!formik.errors.gender}>
                    <InputLabel id="gender-select-label">Gender</InputLabel>
                    <Select
                      labelId="gender-select-label"
                      name="gender"
                      value={formik.values.gender}
                      onChange={formik.handleChange}
                      label="Gender"
                    >
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                  <FormControl fullWidth variant="outlined" size="small" error={formik.touched.accessor_relationship_to_user && !!formik.errors.accessor_relationship_to_user}>
                    <InputLabel id="accessor_relationship_to_user-select-label">Relationship</InputLabel>
                    <Select
                      labelId="accessor_relationship_to_user-select-label"
                      name="accessor_relationship_to_user"
                      value={formik.values.accessor_relationship_to_user}
                      onChange={formik.handleChange}
                      label="Relationship"
                    >
                        {Object.values(RELATIONSHIP_TYPE).map((type) => (
                          <MenuItem key={type} value={type}>{type.toUpperCase()}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          </div>
        </>
      </StandardModal>
    </>
  );
};
export default GrantAccessModal;