import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { COLORS } from "../../shared/constants/layout-constants";
// const useStyles = makeStyles((theme: any) => ({
//   backdrop: {
//     zIndex: theme.zIndex.drawer + 1,
//     color: "#fff",
//   },
// }));

interface ILoadingProps {
  loading: boolean;
}

const Loading: React.FC<ILoadingProps> = (props) => {
  // const classes = useStyles({});
  return (
    <Backdrop style={{zIndex: 5000, color: COLORS.BRAND_PRIMARY}} open={props.loading}>
      {/* <CircularProgress color="inherit" /> */}
      <CircularProgress color="inherit"/>
      {/* <ScheduleOutlined fontSize={"large"} /> */}
    </Backdrop>
  );
};

export default Loading;
