import React, { useState } from 'react';
import { Avatar, Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import { AccountCircle, Logout } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../store/actions/authActions';
import GearIcon from '@mui/icons-material/Settings';
import { useTranslate } from '../../store/translations';

export interface IAvatarCircleProps {
  signed_avatar_url?: string;
  size?: number;
}

const AvatarMenu: React.FC<IAvatarCircleProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const translate = useTranslate();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderAvatarCircle = () => {
    return (
      <>
        <IconButton onClick={handleClick} size="large">
          {props.signed_avatar_url ? (
            <Avatar src={props.signed_avatar_url} alt="avatar" style={{ width: props.size || 35, height: props.size || 35 }} />
          ) : (
            <AccountCircle style={{ fontSize: props.size || 35, color: 'grey' }} />
          )}
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >

          <MenuItem onClick={() => {
            handleClose()
            navigate('Account')
            }}>
            <ListItemIcon>
              <AccountCircle fontSize="small" />
            </ListItemIcon>
            <ListItemText>{translate('Account')}</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => {
            handleClose()
            navigate('/GlobalSettings')
            }}>
            <ListItemIcon>
              <GearIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{translate('Settings')}</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => {
            dispatch(logout({}, () => {
              handleClose()
              navigate('/Login')
            }))
          }}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            <ListItemText>{translate('Logout')}</ListItemText>
          </MenuItem>

        </Menu>
      </>
    );
  };

  return renderAvatarCircle();
};

export default AvatarMenu;
