import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import GearIcon from '@mui/icons-material/Settings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import StoreIcon from '@mui/icons-material/Store';
import Toolbar from '@mui/material/Toolbar';
import LogIcon from '@mui/icons-material/Info';
import ChatIcon from '@mui/icons-material/Chat';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout, refreshUserData } from './store/actions/authActions';
import { useEffect, useState } from 'react';
import UsersPage from './pages/private/user/UsersPage';
import UserViewPage from './pages/private/user/UserViewPage';
import Privacy from './pages/public/privacy/PrivacyPage';
import HomePage from './pages/private/home/HomePage';
import Account from './pages/private/account/AccountPage';
import Terms from './pages/public/terms/Terms';
import ForceLogout from './components/shared/ForceLogout';
import ReleaseNotes from './pages/private/site/ReleaseNotes';
import Uploader from './components/shared/Uploader';
import VerifyEmail from './pages/private/site/VerifyEmail';
import { COLORS } from './shared/constants/layout-constants';
import GlobalSettingsPage from './components/global-settings/GlobalSettingsPage';
import { getGlobalSettings } from './store/actions/globalSettingsActions';
import { useSelector } from './store';
import LogPage from './pages/private/log/LogPage';
import InterviewPage from './pages/private/interview/InterviewPage';
import AvatarMenu from './components/shared/avatar-menu';
import DiscussionPage from './pages/private/discussion/DiscussionPage';
import { TranslatorContext, useTranslate } from './store/translations';
import AccessorPage from './pages/private/access/AccessorPage';
import { LinearProgress, useMediaQuery, useTheme } from '@mui/material';
import { LIFE_TOPICS } from './shared/constants/life-topics';
import { ChatBubbleOutlineRounded, GroupOutlined } from '@mui/icons-material';
import AccessLegaciesPage from './pages/private/access/AccessLegacyPage';
import { getS3URL } from './utils/resource';
import { getMyProfile } from './store/actions/resourceActions';


const drawerWidth = 240;
const authContStyle = {
	marginTop: 0,
	width: 800,
	//   display: 'flex', 
	//   alignItems: 'center', 
	//   justifyContent: 'center'
}

interface IAppAuthenticatedParams {
	userId: string
}

function AppAuthenticated(props: any) {

	const globalSettings = useSelector((state) => state.main.global_settings);
	const translate = useTranslate();
	const { setLanguage } = React.useContext(TranslatorContext);

	const themeMaterial = useTheme();
	const matchesXSorSM = useMediaQuery(themeMaterial.breakpoints.down('sm'));
	const theme = useSelector((state) => state.main.theme);
	const myUser = useSelector(
		(state) => state.main.my_user
	);

	const resources = useSelector((state) => Object.values(state.main.resources));
	const profileUrl = getS3URL(resources, myUser?.id || -1, 'profile', 'thumb', false, false);
	const [userDataRefreshed, setUserDataRefreshed] = useState(false);
	// const users = useSelector((state) => Object.values(state.main.users));
	// const userAccessors = useSelector((state) => Object.values(state.main.user_accessors));
	// const userAccessorIds = userAccessors.map((x) => x.user_id);
	// const myUserAccessors = userAccessors.filter((x) => userAccessorIds.includes(x.user_id));


	const lifeDatas = useSelector((state) => state.main.life_datas);


	const myLifeData = Object.values(lifeDatas).find(x => x.user_id === myUser.id);

	const [dataFetched, setDataFetched] = useState(false);
	useEffect(() => {
		if (!globalSettings?.id) {
			dispatch(getGlobalSettings({}, () => { setDataFetched(true) }))
		}
		if (!profileUrl) {
			dispatch(getMyProfile({}, (data) => { console.log("profile response", data) }));
		}
		dispatch(refreshUserData({}, () => { setUserDataRefreshed(true) }))
	}, [])




	useEffect(() => {
		if (globalSettings.language) {
			setLanguage(globalSettings.language)
		}
	}, [globalSettings])


	let percentComplete = 1;

	if (myLifeData?.current_area === "COMPLETED") {
		percentComplete = 100;
	}
	else {
		const curTopicIndex = LIFE_TOPICS.findIndex((x: any) => x.area === myLifeData?.current_area)
		if (curTopicIndex > -1) {
			percentComplete = curTopicIndex / LIFE_TOPICS.length * 100
		}
	}


	const user_roles = useSelector(
		(state) => state.main?.user_roles || {}
	)

	const roles = useSelector(
		(state) => state.main?.roles || {}
	)

	const [isAdmin, setIsAdmin] = useState(false);

	const dispatch = useDispatch();
	const { window } = props;
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	const navigate = useNavigate();

	useEffect(() => {
		const userRolesArr = Object.values(user_roles);
		const rolesArr = Object.values(roles);

		if (!isAdmin && myUser?.id && userRolesArr.length && rolesArr.length) {

			const adminRole = rolesArr.find((x) => x.name === "Admin");
			if (adminRole) {
				const userAdminRole = userRolesArr.find((x) => x.user_id === myUser.id && x.role_id === adminRole.id)
				if (userAdminRole) {
					setIsAdmin(true);
				}
			}

		}
	}, [myUser, user_roles, roles, isAdmin])

	const iconStyle = { color: theme.colors.icon_primary }

	const drawer = (
		<div>
			<Toolbar />
			<Divider />
			<List >


				<ListItem button key={'home'}
					onClick={(): void => {
						handleDrawerToggle()
						navigate('/Home')
					}}
				>
					<ListItemIcon>
						<DashboardIcon style={iconStyle} />
					</ListItemIcon>
					<ListItemText primary={translate('Home')} />
				</ListItem>

				{myUser.legacy_active && <ListItem button key={'access'}
					onClick={(): void => {
						handleDrawerToggle()
						navigate('/Access')
					}}
				>
					<ListItemIcon>
						<PeopleIcon style={iconStyle} />
					</ListItemIcon>
					<ListItemText primary={translate('Access')} />
				</ListItem>}

				{myUser.legacy_active && <ListItem button key={'interview'}
					onClick={(): void => {
						handleDrawerToggle()
						navigate('/Interview')
					}}
				>
					<ListItemIcon>
						<ChatIcon style={iconStyle} />
					</ListItemIcon>
					<ListItemText primary={translate('Interview')} />
				</ListItem>}

				{isAdmin && <ListItem button key={'users'}
					onClick={(): void => {
						handleDrawerToggle()
						navigate('/Users')
					}}
				>
					<ListItemIcon>
						<GroupOutlined style={iconStyle} />
					</ListItemIcon>
					<ListItemText primary={translate('Users')} />
				</ListItem>}


				{myUser.has_access_to_legacy && <ListItem button key={'access-legacy'}
					onClick={(): void => {
						handleDrawerToggle()
						navigate('/AccessLegacies')
					}}
				>
					<ListItemIcon>
						<ChatBubbleOutlineRounded style={iconStyle} />
					</ListItemIcon>
					<ListItemText primary={translate('Talk to Legacy')} />
				</ListItem>}

				{isAdmin && <ListItem button key={'logs'}
					onClick={(): void => {
						handleDrawerToggle()
						navigate('/log')
					}}
				>
					<ListItemIcon>
						<LogIcon style={iconStyle} />
					</ListItemIcon>
					<ListItemText primary={translate('Logs')} />
				</ListItem>}


				<ListItem button key={'account'}
					onClick={(): void => {
						handleDrawerToggle()
						navigate('/Account')
					}}
				>
					<ListItemIcon>
						<ManageAccountsIcon style={iconStyle} />
					</ListItemIcon>
					<ListItemText primary={translate('Account')} />
				</ListItem>
				<>
					<ListItem button key={'about'}
						onClick={(): void => {
							handleDrawerToggle()
							navigate('/About')
						}}
					>
						<ListItemIcon>
							<StoreIcon style={iconStyle} />
						</ListItemIcon>
						<ListItemText primary={translate('About')} />
					</ListItem>
					<ListItem button key={'global_settings'}
						onClick={(): void => {
							handleDrawerToggle()
							navigate('/GlobalSettings')
						}}
					>
						<ListItemIcon>
							<GearIcon style={iconStyle} />
						</ListItemIcon>
						<ListItemText primary={translate('Settings')} />
					</ListItem>
				</>

			</List>
			<Divider />
			<List>
				<ListItem button key={'logout'}
					onClick={(): void => {
						handleDrawerToggle()
						dispatch(logout({}, () => {
							navigate('/Login')
						}));
					}}
				>
					<ListItemIcon>
						<LogoutIcon style={iconStyle} />
					</ListItemIcon>
					<ListItemText primary={translate('Log Out')} />
				</ListItem>
			</List>
			{/* <TimeZoneSelector/> */}
		</div>

	);
	const container = window !== undefined ? () => window().document.body : undefined;

	if (!userDataRefreshed) {
		return null;
	}

	return (
		<div style={{ width: '100vw', backgroundColor: theme.colors.background, color: theme.colors.font }}>
			<Box sx={{ display: 'flex' }}>
				<CssBaseline />
				<AppBar
					style={{ backgroundColor: COLORS.BRAND_PRIMARY }}
					position="fixed"
					sx={{
						width: { sm: isAdmin ? `calc(100% - ${drawerWidth}px)` : '100%' },
						ml: { sm: `${drawerWidth}px` },
					}}
				>
					<Toolbar>
						<div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
							{/* Logo Section */}
							<div style={{ marginRight: 'auto' }}>
								{/* {isAdmin && <IconButton
									color="inherit"
									aria-label="open drawer"
									edge="start"
									onClick={handleDrawerToggle}
									sx={{ mr: 2, display: { sm: 'none' } }}
								>
									<MenuIcon />
								</IconButton>} */}
								<img
									onClick={() => {
										if (isAdmin){
											handleDrawerToggle()
										}
										else{
											navigate('/Home')
										}
									}}
									alt={'logo'}
									width={matchesXSorSM ? 50 : 115} // Smaller width on xs devices
									style={{ cursor: 'pointer' }}
									src={process.env.PUBLIC_URL + (matchesXSorSM ? '/memory_bloom_logo_white_small.png' : '/memory_bloom_logo_white.png')}
								/>
							</div>

							{/* Progress Bar: Centered both horizontally and vertically */}
							{myUser.legacy_active === true && (
								<div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
									<div style={{ padding: 0, margin: 0, fontSize: '16px', fontWeight: 'bold' }}>{translate('Training Progress')}</div>
									<div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
										<LinearProgress
											variant="determinate"
											value={percentComplete}
											sx={{
												height: 25, // Example to change the height
												width: '50%', // or 500, if a fixed width is needed
												borderRadius: 20, // Apply rounded corners to the container
												backgroundColor: 'lightgray', // Color of the background bar
												'& .MuiLinearProgress-bar': {
													backgroundColor: '#66C18C', // Color of the progress bar itself
													borderRadius: 20,
												}
											}}
										/>&nbsp;{Math.round(percentComplete)}%
									</div>
								</div>
							)}

							{/* Avatar Menu Section */}
							<div
								style={{ marginLeft: 'auto', cursor: 'pointer', fontSize: 12, display: 'flex', alignItems: 'center' }}
							>
								<AvatarMenu signed_avatar_url={profileUrl} />
							</div>
						</div>
					</Toolbar>
				</AppBar>


				{isAdmin && <Box
					component="nav"
					sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
					aria-label="mailbox folders"
				>

					{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
					<Drawer
						PaperProps={{ sx: { backgroundColor: theme.colors.sidebar_background, color: theme.colors.font } }}
						container={container}
						variant="temporary"
						open={mobileOpen}
						onClose={handleDrawerToggle}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
						sx={{
							display: { xs: 'block', sm: 'none' },
							'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
						}}
					>
						{drawer}
					</Drawer>
					<Drawer
						PaperProps={{ sx: { backgroundColor: theme.colors.sidebar_background, color: theme.colors.font } }}
						variant="permanent"
						sx={{
							display: { xs: 'none', sm: 'block' },
							'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
						}}
						open
					>
						{drawer}
					</Drawer>
				</Box>}
				<Box component="main" sx={{ flexGrow: 1, p: { xs: 1, sm: 3 }, marginTop: 8 }}>
					<Routes>
						<Route path="/" element={
							<HomePage />} />
						<Route path="/Home" element={
							<HomePage />} />
						<Route path="Account" element={<Account user={myUser} />} />
						<Route path="Users" element={<UsersPage />} />
						<Route path="Users/:userId" element={<UserViewPage />} />
						<Route path="Access" element={<AccessorPage />} />
						<Route path="AccessLegacies" element={<AccessLegaciesPage />} />
						<Route path="Interview" element={<InterviewPage />} />
						<Route path="AccessLegacy/:userId" element={<DiscussionPage />} />
						<Route path="ForgotPassword" element={<ForceLogout />} />
						<Route path="ResetPassword" element={<ForceLogout />} />
						<Route path="Privacy" element={<Privacy />} />
						<Route path="Terms" element={<Terms />} />
						<Route path="Log" element={<LogPage />} />
						<Route path="About" element={<ReleaseNotes />} />
						<Route path="ReleaseNotes" element={<ReleaseNotes />} />
						<Route path="GlobalSettings" element={<GlobalSettingsPage key={dataFetched} />} />
						<Route path="Upload" element={<div style={authContStyle}><Uploader showToast={true} /></div>} />
						<Route path="VerifyEmail" element={<div style={authContStyle}><VerifyEmail /></div>} />
					</Routes>
				</Box>
			</Box>
		</div>

	)

}

export default AppAuthenticated