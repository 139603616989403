// TranslatorProvider.js
import React, { useState } from 'react';
import { Translator, TranslatorContext } from './store/translations';

export const TranslatorProvider = ({ children }: { children: React.ReactNode }) => {
    const [translator, setTranslator] = useState(new Translator());

    const setLanguage = (language: string | undefined) => {
        const newTranslator = new Translator(language);
        setTranslator(newTranslator);
    };

    return (
        <TranslatorContext.Provider value={{ translator, setLanguage: setLanguage }}>
            {children}
        </TranslatorContext.Provider>
    );
};