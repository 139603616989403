import React, { useEffect, useState } from 'react';
import { StandardUploadInput } from '../../../components/general/StandardUploadInput';
import { useSelector } from '../../../store';
import { getFileExtension } from '../../../shared/utils/file';
import { useSnackbar } from 'notistack';
import { IMAGE_FIT, RESOURCE_TYPE, UPLOAD_COLLECTION_METHOD } from '../../../shared/constants/resource.constants';
import { useDispatch } from 'react-redux';
import { getMyProfile, uploadResourceFile } from '../../../store/actions/resourceActions'
import Loading from '../../../components/general/Loading';
import { getS3URL } from '../../../utils/resource';
import { refreshUserData } from '../../../store/actions/authActions';
import JsonDisplay from '../../../components/shared/JsonDisplay';
import { Badge, Button, Grid, Typography } from '@mui/material';
function AccountViewPage(props: any) {

    const [processing, setProcessing] = useState(false);
    const resources = useSelector((state) => Object.values(state.main.resources));
    const my_user = useSelector((state) => state.main.my_user);
    const [refreshKey, setRefreshKey] = useState(0);

    const profileUrl = getS3URL(resources, my_user?.id || -1, 'profile', 'thumb', false, false);
    const lifeData = useSelector((state) => Object.values(state.main.life_datas))
    const myLifeData = lifeData.find((x: any) => x.user_id === my_user?.id);

    useEffect(() => {
        if (!profileUrl) {
            dispatch(getMyProfile({}, (data) => { console.log("profile response", data) }));
        }
        dispatch(refreshUserData({}));
    }, []);

    let reloadTimeout = null as any;

    const setNewTimeout = () => {
        clearTimeout(reloadTimeout);
        reloadTimeout = setTimeout(() => {
            dispatch(getMyProfile({}));
            setRefreshKey(refreshKey + 1);
        }, 500)
    }

    useEffect(() => {
        return () => clearTimeout(reloadTimeout);
    }, []);

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const handleSubmit = (selectedFile: File) => {

        return new Promise(function (resolve, reject) {
            setProcessing(true);
            try {
                getFileExtension(selectedFile!.name);
            } catch (e) {
                enqueueSnackbar(`Invalid File Extension.`, { variant: 'error' });
                return;
            }

            const uploadData = {
                name: my_user?.id,
                model: 'profile',
                modelId: my_user?.id,
                extension: getFileExtension(selectedFile!.name),
                filename: selectedFile!.name,
                fileSize: selectedFile!.size,
                uploadCollectionMethod: UPLOAD_COLLECTION_METHOD.REPLACE,
                type: RESOURCE_TYPE.IMAGE,
                parts: 1,
                file: selectedFile,
                rootPath: "private",
                primary: true,
                conversions: [
                    {
                        width: 256,
                        height: 256,
                        fit: IMAGE_FIT.COVER,
                        imageType: getFileExtension(selectedFile!.name),
                        appendName: "thumb",
                        conversion: "thumb",
                        rootPath: "private"
                    }
                ]
            };

            dispatch(
                uploadResourceFile(
                    uploadData,
                    (data: any) => {
                        setProcessing(false);
                        enqueueSnackbar(`Image uploaded.`, { variant: 'success' });
                        setNewTimeout();
                        return resolve(true);
                    },
                    () => {
                        setProcessing(false);
                        enqueueSnackbar(`Image upload failed.`, { variant: 'error' });
                        return resolve(true);
                    },
                ),
            );
        })


    };

    return (
        <>
            <Loading loading={processing} />


            <Grid container spacing={2} style={{ textAlign: 'center', paddingLeft: 16, paddingRight: 16 }} direction="column">

                <h2>{my_user?.first_name + " " + my_user?.last_name}</h2>
                <b>{my_user.email}</b>
                <br /><br />
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: 8 }} key={refreshKey}>
                    <StandardUploadInput
                        uploadType="profile"
                        initialImage={profileUrl}
                        handleSubmit={() => { }}
                        forceKey={refreshKey}
                        showPreview={true}
                        handleExif={true}
                        onFileSelected={(selectedFiles: FileList) => { handleSubmit(selectedFiles[0]) }}
                        width={100}
                    />
                </div>


                {my_user.legacy_active && <>
                    <Grid style={{ paddingRight: 24 }} item>
                        <Typography variant="body1" component="div">
                            Account Status: <Badge style={{ marginLeft: 24, marginBottom: 4 }} color="success" badgeContent={'Active'} />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">
                            Your account is ready to go to start recording your legacy. To improve the model click on the interview button and continue the conversation.
                        </Typography>
                    </Grid>
                </>}
                {!my_user.legacy_active &&
                    <>
                        <Grid style={{ paddingRight: 24 }} item>
                            <Typography variant="body1" component="div">
                                Account Status: <Badge style={{ marginLeft: 24, marginBottom: 4 }} color="warning" badgeContent={'Inactive'} />
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">
                                You don't have an account setup to record your legacy, would you like to start one now?
                            </Typography>
                            <Typography variant="body1">
                                <Button style={{ marginTop: 16 }} variant={'outlined'} onClick={() => { }}>Subscribe</Button>
                            </Typography>
                        </Grid>
                    </>
                }
            </Grid>
            {/* {myLifeData && <div>
                <JsonDisplay title={'Life Data'} inputData={myLifeData} />
            </div>} */}
        </>
    )
}

export default AccountViewPage