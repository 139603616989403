import { Button } from '@mui/material';
import React, { useState } from 'react';
import ImageIcon from '@mui/icons-material/ImageOutlined'
import FileIcon from '@mui/icons-material/FilePresentOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import EXIF from "exif-js";
import { dataURLtoFile } from '../../shared/utils/gen-utils';

interface IStandardUploadInputProps {
    initialImage?: string
    handleSubmit: (data: any) => void
    infoText?: string
    onFileSelected?: (data: any) => void
    showUploadButton?: boolean
    showPreview?: boolean
    accept?: string
    multiple?: boolean
    uploadType?: string
    forceKey?: any
    width?: number
    handleExif?: boolean
}

export const StandardUploadInput: React.FC<IStandardUploadInputProps> = (props): JSX.Element => {

    const hiddenFileInput = React.useRef<any>(null);

    const handleClick = (event: any) => {
        hiddenFileInput!.current!.click();
    };

    const imageSrc = () => {
        if (props.initialImage) {
            return props.initialImage 
        }
        return '';
    };

    const [selectedFiles, setSelectedFiles] = useState<FileList>();

    const handleNonExifFile = (curFile: any) => {
        return new Promise((resolve, reject) => {
            return resolve(curFile);
        })
    }



    const handleExifFile = async (curFile: any, exifData: any) => {
        return new Promise((resolve, reject) => {
            const orientation = EXIF.getTag(curFile, "Orientation");
            if (orientation && !isNaN(orientation) && orientation !== 1) {
                var img = document.createElement("img");
                var reader = new FileReader();
                reader.onload = function (e) {
                    var dimImage = new Image();

                    dimImage.onload = function () {
                        img.src = e.target!.result as string;
                        var canvas = document.createElement("canvas");
                        canvas.id = new Date().getTime().toString();
                        var ctx = canvas.getContext("2d");
                        canvas.width = dimImage.width;
                        canvas.height = dimImage.height;

                        ctx!.drawImage(
                            img,
                            0,
                            0,
                            dimImage.width,
                            dimImage.height,
                            0,
                            0,
                            dimImage.width,
                            dimImage.height
                        );
                        switch (orientation) {
                            case 2:
                                // horizontal flip
                                ctx!.translate(canvas.width, 0);
                                ctx!.scale(-1, 1);
                                break;
                            case 3:
                                // 180° rotate left
                                ctx!.translate(canvas.width, canvas.height);
                                ctx!.rotate(Math.PI);
                                break;
                            case 4:
                                // vertical flip
                                ctx!.translate(0, canvas.height);
                                ctx!.scale(1, -1);
                                break;
                            case 5:
                                // vertical flip + 90 rotate right
                                ctx!.rotate(0.5 * Math.PI);
                                ctx!.scale(1, -1);
                                break;
                            case 6:
                                // 90° rotate right
                                ctx!.rotate(0.5 * Math.PI);
                                ctx!.translate(0, -canvas.height);
                                break;
                            case 7:
                                // horizontal flip + 90 rotate right
                                ctx!.rotate(0.5 * Math.PI);
                                ctx!.translate(canvas.width, -canvas.height);
                                ctx!.scale(-1, 1);
                                break;
                            case 8:
                                // // 90° rotate left
                                ctx!.rotate(-0.5 * Math.PI);
                                ctx!.translate(-dimImage.width, 0);
                                break;
                        }

                        ctx!.restore();

                        const dataUrl = canvas.toDataURL("image/jpeg");

                        return resolve(dataURLtoFile(dataUrl, curFile.name || "test.jpeg"));
                    };

                    dimImage.src = e.target!.result as string;
                };
                reader.readAsDataURL(curFile);
            } else {
                return resolve(curFile);
            }
        });
    };


    const exifPromise = (curFile: any) => {
        return new Promise((resolve, reject) => {
            EXIF.getData(curFile, function () {
                var exifData = EXIF.pretty(curFile);
                if (exifData) {
                    return resolve(exifData)
                }
                else {
                    return resolve(null)
                }
            })
        })
    }

    const handleChangeFiles = async (e: any) => {

        console.log("handleChangeFiles", e.target.files)
        const newFileArr = [] as any[]

        if (e.target.files) {
            for (var i = 0; i < e.target.files.length; i++) {

                const curFile = e.target.files[i] as any;

                if (!props.handleExif) {
                    const newFile = await handleNonExifFile(curFile)
                    newFileArr.push(newFile)
                    continue;
                }

                if (curFile && curFile.name) {
                    const exifData = await exifPromise(curFile);
                    if (exifData) {
                        const newFile = await handleExifFile(curFile, exifData)

                        newFileArr.push(newFile)
                    }
                    else {
                        const newFile = await handleNonExifFile(curFile)
                        newFileArr.push(newFile)
                    }
                }
            };
        }

        const fileArr = Array.from(newFileArr || []);
        if (props.onFileSelected) {
            props.onFileSelected(fileArr)
        }
    }

    return (
        <>
            {
                props.uploadType === 'images' && <div style={{ display: 'relative' }}>
                    <div>Select image from your computer</div>
                    <div style={{ marginTop: 32, marginBottom: 8, display: 'flex', alignContent: 'center', justifyContent: 'center' }}><Button variant="outlined" startIcon={<ImageIcon />} onClick={handleClick} >
                        Select Image
                    </Button></div>

                </div>
            }
            {props.uploadType === 'files' && <Button style={{ marginBottom: 8 }} variant="contained" startIcon={<FileIcon />} onClick={handleClick} >
                Select File
            </Button>}
            <span style={{ fontSize: 12 }}>{props.infoText || ''}</span>
            <div className="flex flex-col items-center mt-2">
                <label
                    htmlFor="picture"
                    className="px-5 py-1 mt-6 bg-white border rounded-lg shadow cursor-pointer hover:bg-purple-600 hover:text-white"
                >
                    {/* <span className="mt-2 text-base leading-normal">{selectedFile?.name || ''}</span> */}
                    <input
                        ref={hiddenFileInput}
                        multiple={props.multiple || false}
                        type="file"
                        accept={props.accept || ".png, .jpg, .jpeg"}
                        id="picture"
                        name="picture"
                        className="hidden"
                        onChange={(e) => {
                            console.log("on change called")
                            return handleChangeFiles(e)
                        }}
                        style={{ display: 'none' }}
                    />
                </label>
            </div>
            {props.showPreview && <div onClick={handleClick} style={{ cursor: 'pointer' }}> {props.initialImage ?
                <span>
                    <img
                        className={'avatar'}
                        style={{ width: props.width || 100, height: props.width || 100 }}
                        src={props.initialImage + "&forceKey=" + props.forceKey || 'none'}
                    />
                </span>
                : <div className={'userImageWrapper'}>
                    <PermIdentityOutlinedIcon />
                </div>} </div>}
            {props.showUploadButton && <Button variant={'outlined'} onClick={() => {
                props.handleSubmit(selectedFiles)
            }}>
                Submit
            </Button>
            }
        </>
    );
};
