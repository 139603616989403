import * as React from 'react';
import { useTranslate } from '../../../store/translations';
import AccessorTable from '../../../components/user/AccessorTable';
import { DESCRIPTION_OF_GRANT_ACCESS_TO } from '../../../shared/constants/app-constants';

function AccessorPage(props: any) {

	const translate = useTranslate();

	return <>
		<p>{translate(DESCRIPTION_OF_GRANT_ACCESS_TO)}</p>
		<AccessorTable
			title={translate('Accessor Table')}
		/>
	</>
}

export default AccessorPage