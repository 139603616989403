// Translator.js
import React, { createContext, useContext, useState } from 'react';

import translationsJSON from './translations.json';

export const translations = translationsJSON as any

export const Translate = (key: string, lang: string | undefined) => {
  if (!lang) {
    return "";
  }
  if (lang === "en") {
    return key;
  }
  if (translations[key] && translations[key][lang]) {
    return translations[key][lang];
  } else {
    return `TRANSLATE <${key}> ${lang}`;
  }
};

export class Translator {

   language: string;
    missingTranslations: any;

  constructor(language = "en") {
    this.language = language;
    this.missingTranslations = {};
  }

  translate = (key: string) => {
    let translationValue = Translate(key, this.language);
    if (translationValue.includes("TRANSLATE <")) {
      this.missingTranslations[key] = { 'es': '' };
      console.log('MISSING TRANSLATIONS', JSON.stringify(this.missingTranslations));
    }
    return translationValue;
  };
}

interface TranslatorContextType {
  translator: Translator;
  setLanguage: (language: string) => void;
}

// Create a Context
export const TranslatorContext = createContext<TranslatorContextType>({
  translator: new Translator(),
  setLanguage: () => {},
});

// Export the useContext Hook
export const useTranslator = () => useContext(TranslatorContext);

export const useTranslate = () => {
  const { translator } = useTranslator(); // Assuming useTranslator returns an object with a translator property
  return translator.translate;
};