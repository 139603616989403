
import React from "react";

interface IReleaseNotes {
}

export const release_notes = [
	{
		date: '2024-4-07',
		version: '0.0.1',
		environment: 'beta',
		notes: [
			{
				area: 'Application',
				note: 'Initial Release .',
			}
		]
	}
]

const getEnvironmentDiv = (env: string) => {
	if (env === 'beta' || env === 'development' || env === 'staging') {
		return <span>{env}</span>
	}
	return null;
}

const ReleaseNotes: React.FC<IReleaseNotes> = (props) => {

	return (
		<div>
			<h2>History</h2>
			<h1>Version: {release_notes[0].version}</h1>
			{release_notes.map((rn, rnIndex) => {
				return (
					<div key={rnIndex}>
						<section>
							<article>
								Version {rn.version} ({getEnvironmentDiv(rn.environment)})
								<br />
								Release Date: {rn.date}
								<ul>
									{rn.notes.map((note, noteIndex) => {
										return (
											<li key={noteIndex}>{note.area}: {note.note}</li>
										)
									})}
								</ul>
							</article>
						</section>
						<hr></hr>
					</div>
				)
			})}
		</div>

	)

}
export default ReleaseNotes