import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getInteractionHistory } from '../../../store/actions/interactionActions';
import { useSelector } from '../../../store';
import { IRootState } from '../../../interfaces/store';
import { nextDiscussionPrompt, resetAssistantDiscussion } from '../../../store/actions/interviewActions';
import ChatComponent from '../../../components/chat/ChatComponent';
import { useNavigate, useParams } from 'react-router-dom';
import { IUser } from '../../../shared/interfaces/model/user.interface';
import { useTranslate } from '../../../store/translations';
import AvatarCircle from '../../../components/shared/avatar-circle';
import { getS3URL } from '../../../utils/resource';

function DiscussionPage(props: any) {

  const params = useParams<{ userId: string }>();
  const userIdNum = Number(params.userId)
  const navigate = useNavigate();

  if (isNaN(userIdNum)) {
    navigate('/Home')
  }

	const translate = useTranslate();
  
  const [waitingForMessage, setWaitingForMessage] = useState(false);
  const [tempUserMessage, setTempUserMessage] = useState('');
  const dispatch = useDispatch();
  const interactions = useSelector((state: IRootState) => state.main.interactions);
  const assistantDiscussionText = useSelector((state: IRootState) => state.main.streams.assistant_discussion.text);
  const myUser = useSelector((state: IRootState) => state.main.my_user);

  const lifeDatas = useSelector((state: IRootState) => state.main.life_datas);
  const userLifeData = Object.values(lifeDatas).find((x: any) => x.user_id === userIdNum);
  const threads = useSelector((state: IRootState) => state.main.threads);
  const relevantThread = Object.values(threads).find((x: any) => x.life_data_id === userLifeData?.id && x.accessor_user_id === myUser.id && x.context === 'discussion');


  const users = useSelector((state: IRootState) => Object.values(state.main.users));
  const user = users.find((x: IUser) => x.id === userIdNum);

  const resources = useSelector((state) => Object.values(state.main.resources));
  const profileUrl = getS3URL(resources, user?.id || -1, 'profile', 'thumb', false, false);
  

  console.log("relevantThread", relevantThread)

  let threadInteractions = Object.values(interactions).filter((x: any) => x.thread_id === relevantThread?.thread_id);
  threadInteractions = threadInteractions.sort((a: any, b: any) => a.created_at - b.created_at).splice(-20);
  //console.log("userInteractions", userInteractions)

  const handleSendMessage = (newMessage: string) => {

    dispatch(resetAssistantDiscussion({}));

    setWaitingForMessage(true);
    if (userLifeData) {
      console.log("dispatch the chat")
      dispatch(nextDiscussionPrompt({
        legacyUserId: userIdNum,
        threadId: relevantThread?.thread_id, //TODO just use the jwt
        userInput: newMessage
      }, (data: any) => {
        console.log("success data", data)
        setWaitingForMessage(false);
      }, (failData) => {
        console.log("fail data", failData)
        setWaitingForMessage(false);

      }))
      setTempUserMessage(newMessage);
    }
  };

  useEffect(() => {
    dispatch(getInteractionHistory({ 
      context: 'discussion',
      legacyUserId: userIdNum
    }, (data) => {
      console.log("success data", data)
    }))
  }, [])

  return (
    <>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: 8}}>
        <h2 style={{ marginTop: 0, paddingTop: 0 }}>{translate("Speak to ") + (user?.first_name || '') + " " + (user?.last_name || '') + translate('legacy')}</h2>
   
        {profileUrl && <AvatarCircle
          size={96}
          signed_avatar_url={profileUrl}
        />}
      </div>

       <ChatComponent 
      interactions={threadInteractions} 
      waitingForMessage={waitingForMessage} 
      assistantText={assistantDiscussionText} 
      tempUserMessage={tempUserMessage} 
      handleSendMessage={handleSendMessage}    
    />
    </>

  );
}

export default DiscussionPage;
