import React from 'react';
import { Box } from '@mui/material';

// Custom styled component for the bouncing dot
const BounceDot = ({delay}: {delay: number}) => (
  <div style={{
    width: '10px',
    height: '10px',
    backgroundColor: '#0c3129', // Directly setting a color
    borderRadius: '50%',
    margin: '0 5px',
    animation: `bounce 0.6s ${delay}s infinite alternate`,
  }} />
);

// Keyframes for the bounce animation added directly to the global style
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = `
@keyframes bounce {
  0% { transform: translateY(0); }
  100% { transform: translateY(-15px); }
}`;
document.head.appendChild(styleSheet);

const LoadingDots = () => {
  return (
    <Box display="flex" alignItems="center">
      <BounceDot delay={0} />
      <BounceDot delay={0.2} />
      <BounceDot delay={0.4} />
    </Box>
  );
};

export default LoadingDots;