import { isString } from 'lodash';
import errors from './errors';

function stringFallback(value: any, defaultValue: any): string {
  if (isString(value)) {
    return value;
  }
  return defaultValue;
}

function loopStringFallbacks(values: any[], defaultValue: any): string {
  for (let i = 0; i < values.length; i++) {
    if (isString(values[i])) {
      return values[i];
    }
  }
  return defaultValue;
}



export function GetFriendlyError(eData: any): string {
  console.log("error?", eData?.statusText)
	try{
    return loopStringFallbacks([
      eData?.data?.error,
      eData?.message,
      eData?.statusText
    ], 'Error: Unknown Error')
	}
	catch(e){
	
	}
	return 'Error: Unknown Error'
  }


export const boolToString = (val: any, trueVal: string, falseVal: string) => {
  let boolval = false;
  if (typeof val === 'boolean'){
    boolval = val;
  }
  else{
    if (typeof val === "string"){
      if (val.toLowerCase() === 'true'){
        boolval = true;
      }
    }
  }
  if (boolval === true){
    return trueVal
  }
  return falseVal
}

export const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const getUrlParam = (param: string) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(param)
}
