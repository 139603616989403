import { AccountCircle } from "@mui/icons-material";
import React, { useEffect } from "react";

export interface IAvatarCircleProps {
        signed_avatar_url?: string
        size?: number
}

const AvatarCircle: React.FC<IAvatarCircleProps> = (props) => {

        const renderAvatarCircle = () => {
                if (props.signed_avatar_url) {
                        return (
                                <img src={props.signed_avatar_url} alt="avatar" style={{ width: props.size || 35, height: props.size || 35, borderRadius: '50%', objectFit: 'cover', objectPosition: 'center' }} />
                        )
                }
                else {
                        return (
                                <div><AccountCircle style={{ fontSize: props.size || 35, color: 'grey' }} /></div>
                        )
                }
        }

        return (renderAvatarCircle())
};

export default AvatarCircle;