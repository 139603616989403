import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { APP_DESCRIPTION_P1, APP_DESCRIPTION_P2 } from '../../../shared/constants/app-constants';

const Landing = () => {
    const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%', // Full viewport height
      }}
    >
      <Button onClick={() => {
        navigate('/Login')
      }} variant="contained">Login</Button>

      <p style={{maxWidth: 1200, paddingTop: 32, paddingLeft: 64, paddingRight: 64}}>{APP_DESCRIPTION_P1}</p>
      <p style={{maxWidth: 1200, paddingLeft: 64, paddingRight: 64}}>{APP_DESCRIPTION_P2}</p>
      <Box
        component="img"
        sx={{
          maxWidth: '50%', // Ensures the image is responsive
          maxHeight: '30vh', // Limits the image size
          marginTop: 2, // Adds some space between the button and the image
        }}
        src={`${process.env.PUBLIC_URL}/rememberme.webp`} // Replace with your image URL
        alt="Centered Image"
      />
    </Box>
  );
};

export default Landing;