import { ACTIONS } from "../../enums/actions";
import { genericAction } from "./genericAction";

export const getUser = genericAction(ACTIONS.GET_USER);
export const getUsers = genericAction(ACTIONS.GET_USERS);
export const getRoles = genericAction(ACTIONS.GET_ROLES);
export const updateUser = genericAction(ACTIONS.UPDATE_USER);
export const userActivation = genericAction(ACTIONS.USER_ACTIVATION);
export const downloadUsersReport = genericAction(ACTIONS.DOWNLOAD_USERS_REPORT);
export const addRemoveRole = genericAction(ACTIONS.ADD_REMOVE_ROLE);
export const createUser = genericAction(ACTIONS.CREATE_USER);
export const getUserStats = genericAction(ACTIONS.GET_USER_STATS);

export const getUserAccessors = genericAction(ACTIONS.GET_USER_ACCESSORS);
export const grantUserAccess = genericAction(ACTIONS.GRANT_USER_ACCESS);
export const revokeUserAccess = genericAction(ACTIONS.REVOKE_USER_ACCESS);
export const performUserMaintenance = genericAction(ACTIONS.PERFORM_USER_MAINTENANCE);