import React, { useEffect, useState } from 'react';
import { DESCRIPTION_OF_GRANT_ACCESS_TO, DESCRIPTION_OF_YOU_HAVE_ACCESS_TO } from '../../../shared/constants/app-constants';
import { Box, Card, CardHeader, CardMedia, Grid, Typography } from '@mui/material';
import { useTranslate } from '../../../store/translations';
import { useNavigate } from 'react-router-dom';
import { useSelector } from '../../../store';
import { IUserAccessorData } from '../../../store/hooks/get-user-accessors';
import { refreshUserData } from '../../../store/actions/authActions';
import { useDispatch } from 'react-redux';

interface IHomePage {

}

interface CardItem {
  title: string;
  imageUrl: string;
  hoverImageUrl: string;
  text: string;
  onClick: () => void;
}



const HomePage: React.FC<IHomePage> = (props) => {


  const navigate = useNavigate();
  const translate = useTranslate();
  const dispatch = useDispatch();
  const myUser = useSelector((state) => state.main.my_user);


  const interviewCard = {
    title: translate("Interview"),
    imageUrl: `${process.env.PUBLIC_URL}/interview_grey.webp`,
    hoverImageUrl: `${process.env.PUBLIC_URL}/interview.webp`,
    text: translate("Your interview with the RememberMe AI is your first step to creating your legacy. It will help you to reflect on your life and share your memories, values, and wisdom with your loved ones."),
    onClick: () => navigate('/Interview'),
  }
  const accessCard = {
    title: translate("Access"),
    imageUrl: `${process.env.PUBLIC_URL}/rememberme_grey.webp`,
    hoverImageUrl: `${process.env.PUBLIC_URL}/rememberme.webp`,
    text: translate(DESCRIPTION_OF_GRANT_ACCESS_TO),
    onClick: () => navigate('/Access'),
  }
  const talkToLegacyCard = {
    title: translate('Talk to legacy'),
    imageUrl: `${process.env.PUBLIC_URL}/family_access_grey.webp`,
    hoverImageUrl: `${process.env.PUBLIC_URL}/family_access.webp`,
    text: translate(DESCRIPTION_OF_YOU_HAVE_ACCESS_TO),
    onClick: () => navigate('/AccessLegacies'),
  }
  const createLegacyCard = {
    title: translate('Create your legacy'),
    imageUrl: `${process.env.PUBLIC_URL}/create_legacy_grey.webp`,
    hoverImageUrl: `${process.env.PUBLIC_URL}/create_legacy.webp`,
    text: translate("Start your journey of preserving your legacy. You will be guided through an interview and have the ability to upload content."),
    onClick: () => navigate('/Account'),
  }

  let cardsData = [] as CardItem[];

  if (myUser.legacy_active) {
    cardsData.push(
      interviewCard
    );
    cardsData.push(
      accessCard
    )
  }

  if (myUser.has_access_to_legacy){
    cardsData.push(talkToLegacyCard)
  }

  if (!myUser.legacy_active) {
    cardsData.push(createLegacyCard)
  }

  if (!myUser.id){
    return;
  }

  return (
    <Box sx={{
      maxWidth: '1200px',
      margin: '0 auto', // Centers the div
      width: '100%', // Makes the div take up available width up to maxWidth
      // Additional styling here
    }}>
      <Grid container spacing={2}>
        {cardsData.map((card, index) => {
          // State to manage hover
          const [isHovered, setIsHovered] = useState(false);

          return (


            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                onClick={card.onClick}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                sx={{ display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: '#F5F5F5', cursor: 'pointer' }}>
                <CardHeader
                  title={card.title}
                  titleTypographyProps={{ align: 'center' }}
                  sx={{ paddingBottom: 0 }}
                />
                <Box
                  sx={{
                    paddingTop: '100%', // Maintain aspect ratio 1:1
                    position: 'relative',
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                    src={isHovered ? card.hoverImageUrl : card.imageUrl}
                    alt="Card image"
                  />
                </Box>
                <Typography variant="body1" sx={{ padding: 2, flexGrow: 1 }}>
                  {card.text}
                </Typography>
              </Card>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  );
};

export default HomePage;
