
export const LIFE_QUESTIONS = [
  { seed_uuid: "R4nd0m1234", text: 'Given the choice of anyone in the world, whom would you want as a dinner guest?' },
  { seed_uuid: "A1b2C3d4E5", text: 'Would you like to be famous? In what way?' },
  { seed_uuid: "f6g7h8i9j0", text: 'Before making a telephone call, do you ever rehearse what you are going to say? Why?' },
  { seed_uuid: "Klmn567opq", text: 'What would constitute a "perfect" day for you?' },
  { seed_uuid: "rst987Uvwx", text: 'When did you last sing to yourself? To someone else?' },
  { seed_uuid: "yz0123ABCD", text: 'If you were able to live to the age of 90 and retain either the mind or body of a 30-year-old for the last 60 years of your life, which would you want?' },
  { seed_uuid: "EFGH4567IJ", text: 'Do you have a secret hunch about how you will die?' },
  { seed_uuid: "KLMN8opqrs", text: 'Name three things you and your partner appear to have in common.' },
  { seed_uuid: "tuvWXYz123", text: 'For what in your life do you feel most grateful?' },
  { seed_uuid: "4567890abc", text: 'If you could change anything about the way you were raised, what would it be?' },
  { seed_uuid: "defGHijk12", text: 'If you could wake up tomorrow having gained any one quality or ability, what would it be?' },
  { seed_uuid: "3lmnopQR45", text: 'If a crystal ball could tell you the truth about yourself, your life, the future, or anything else, what would you want to know?' },
  { seed_uuid: "STUvwx6789", text: "Is there something that you've dreamed of doing for a long time? Why haven't you done it?" },
  { seed_uuid: "YZab012cde", text: 'What is the greatest accomplishment of your life?' },
  { seed_uuid: "fghIJKLMN3", text: 'What do you value most in a friendship?' },
  { seed_uuid: "4567opqrst", text: 'What is your most treasured memory?' },
  { seed_uuid: "UVWxyzAB12", text: 'What is your most terrible memory?' },
  { seed_uuid: "345CDEfghi", text: 'If you knew that in one year you would die suddenly, would you change anything about the way you are now living? Why?' },
  { seed_uuid: "jklmnOPQRS", text: 'What does friendship mean to you?' },
  { seed_uuid: "T567890abc", text: 'What roles do love and affection play in your life?' },
  { seed_uuid: "deFghiJKLM", text: "How close and warm is your family? Do you feel your childhood was happier than most other people's?" },
  { seed_uuid: "Nopqr56789", text: 'How do you feel about your relationship with your mother?' },
  { seed_uuid: "STUvwxyz01", text: 'Make three true "we" statements about you and another important person. For instance, "We are both in this room feeling..."' },
  { seed_uuid: "23abcdEfgh", text: 'Complete this sentence: "I wish I had someone with whom I could share..."' },
  { seed_uuid: "ijklMNOpqr", text: 'If you were going to become a close friend with a partner, please share what would be important for him or her to know.' },
  { seed_uuid: "StuvwxyZAB", text: "Tell me what you like about your partner or a potential partner; be very honest this time, saying things that you might not say to someone you've just met." },
  { seed_uuid: "CD456EfgHi", text: 'Share with me an embarrassing moment in your life.' },
  { seed_uuid: "jkLmnOpqR5", text: 'When did you last cry in front of another person? By yourself?' },
  { seed_uuid: "6789Tabcde", text: 'What, if anything, is too serious to be joked about?' },
  { seed_uuid: "FghIJKLMNO", text: `If you were to die this evening with no opportunity to communicate with anyone, what would you most regret not having told someone? Why haven't you told them yet?` },
  { seed_uuid: "pqrstUVWXY", text: `Your house, containing everything you own, catches fire. After saving your loved ones and pets, you have time to safely make a final dash to save any one item. What would it be? Why?` },
  { seed_uuid: "Z123456abc", text: 'Of all the people in your family, whose death would you find most disturbing? Why?' }
];
  // 'Alternate sharing something you consider a positive characteristic of your partner. Share a total of five items.',

export const LIFE_TOPICS = [
  {
    "area": "Early Childhood",
    "sub_area": [
      "Place and date of birth",
      "Family background and siblings",
      "Mother and father's occupation",
      "Mother and father's personality",
      "Siblings personalities and activities you shared",
      "Memories of extended family such as grandparents, aunts, uncles, and cousins",
      "Early childhood memories",
      "Early childhood friends",
      "Significant childhood events",
      "Family recreation and travel, birthday parties, or family traditions."
    ]
  },
  {
    "area": "School Years",
    "sub_area": [
      "Early education (kindergarten, elementary school)",
      "Favorite subjects and activities",
      "Important friendships and relationships",
      "Sports, music, and hobbies.",
      "Challenges and achievements"
    ]
  },
  {
    "area": "Adolescence",
    "sub_area": [
      "High school experience",
      "Extracurricular activities and hobbies",
      "Significant friendships and relationships",
      "Early love, first kiss, or crush",
      "Major challenges and how they were overcome",
      "Future aspirations at the time"
    ]
  },
  {
    "area": "Early Adulthood",
    "sub_area": [
      "College/university education or other training",
      "Key friendships and relationships",
      "Career beginnings",
      "Major life decisions and turning points",
      "Challenges and obstacles faced, decisions you regret",
    ]
  },
  {
    "area": "Career Development",
    "sub_area": [
      "Career path and progression",
      "Significant accomplishments and setbacks",
      "Influential mentors and figures",
      "Changes in career direction"
    ]
  },
  {
    "area": "Personal Life",
    "sub_area": [
      "Significant relationships and partnerships",
      "Marriage and children",
      "Personal hobbies and interests",
      "Life philosophies and values"
    ]
  },
  {
    "area": "Middle Age",
    "sub_area": [
      "Mid-career developments",
      "Family dynamics and milestones",
      "Personal and professional challenges",
      "Reflections on accomplishments and regrets"
    ]
  },
  {
    "area": "Later Life",
    "sub_area": [
      "Retirement and life changes",
      "Legacy and contributions",
      "Reflections on life lessons and advice for younger generations"
    ]
  },
  {
    "area": "Continuous Aspects Throughout Life",
    "sub_area": [
      "Evolving interests and hobbies",
      "Long-standing and influential friendships",
      "Major health events or challenges",
      "Changes in personal values and beliefs"
    ]
  }
]
