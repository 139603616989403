import * as React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from './interfaces/store';
import AppAuthenticated from './AppAuthenticated';
import AppPublic from './AppPublic';
import './App.css';
import ErrorBoundary from './components/shared/ErrorBoundary';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { initSocket } from './store/actions/socketActions';
import Loading from "./components/general/Loading";
import { COLORS } from './shared/constants/layout-constants';
import { useTranslate } from './store/translations';

function App() {

	const translate = useTranslate();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const error = useSelector((state: IRootState) => state.main.error);
	const success = useSelector((state: IRootState) => state.main.success);
	const [socketInited, setSocketInited] = useState(false);
	const user = useSelector((state: IRootState) => state.main.my_user);
	const loading = useSelector((state: IRootState) => state.main.loading);

	useEffect(() => {
	  if (error.error){
		enqueueSnackbar(error.error, { variant: 'error' });
	  }
	}, [error])
	useEffect(() => {
	  if (success.message){
		enqueueSnackbar(success.message, { variant: 'success' });
	  }
	}, [success])


	const email = useSelector(
		(state: IRootState) => state.main.my_user?.email
	);

	// STANDARD SOCKET?
	useEffect(() => {
		if (user?.id && !socketInited) {
			setSocketInited(true)
			dispatch(initSocket())
		}
	}, [user])

	// useEffect(() => {

	// 	console.log("useEffect socket_id", socket_id)
	// 	if (socket_id) {
	// 	  console.log("process.env.REACT_APP_SERVER_DOMAIN", process.env.REACT_APP_SERVER_DOMAIN)
	// 	  // Establish WebSocket connection
	// 	  const socket = io(process.env.REACT_APP_API_HOST || '', {
	// 		withCredentials: true, // Ensure cookies are sent
	// 		query: {
	// 		  socket_id: socket_id  // Replace 'yourDataHere' with the actual data you want to send
	// 		}
	// 		// Additional options can be added here if needed
	// 	  });
	// 	  // Handle WebSocket events
	// 	  socket.on("connect", () => {
	// 		console.log("WebSocket Connected");
	// 		// You can also send authentication data through WebSocket if needed
	// 		socket.emit("authenticate", { socket_id: socket_id });
	// 	  });
	
	// 	  socket.on("userMessage", (message) => {
	// 		const context = message?.context?.toLowerCase()
	// 		if (context === 'interview_assistant_message') {
	// 			console.log("recieved a message", message)
	// 		  dispatch(mergeAssistantMessageAction({ text: message.content }))
	// 		}
	// 	  });
	
	// 	  socket.on("disconnect", () => {
	// 		console.log("WebSocket Disconnected");
	// 	  });
	
	// 	}
	
	//   }, [socket_id]);


	return (
<div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
    <ErrorBoundary>
        <Loading loading={loading} />
        <div key={email} style={{ flex: 1 }}>
            {email ? <AppAuthenticated /> : <AppPublic />}
        </div>
    </ErrorBoundary>
    <footer style={{ textAlign: 'center', backgroundColor: COLORS.BRAND_PRIMARY, color: 'white', height: '30px' }}>
        © {new Date().getFullYear()} {translate('Corbin Brooks. All rights reserved')}.
    </footer>
</div>
	);
}

App.propTypes = {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,
};

export default App;