import { isArray } from 'lodash'
import { normalize, schema } from 'normalizr'
import { put } from 'redux-saga/effects'
import { getThemeClasses, getThemeColors } from '../../shared/constants/layout-constants'

export const globalSettingsSchema = new schema.Entity("global_settings");
export const metaSchema = new schema.Entity('meta')
export const productSchema = new schema.Entity('products')
export const resourceSchema = new schema.Entity('resources')
export const roleSchema = new schema.Entity('roles')
export const userRoleSchema = new schema.Entity('user_roles')
export const userSchema = new schema.Entity('users')
export const personSchema = new schema.Entity('persons')
export const productProductSchema = new schema.Entity('product_products')
export const drinksSchema = new schema.Entity('drinks')
export const drinksCategorySchema = new schema.Entity('drink_categories')
export const knowledgeBaseSchema = new schema.Entity('knowledge_bases')
export const mincyNoteSchema = new schema.Entity('mincy_notes')
export const conversationSchema = new schema.Entity('conversations')
export const goalResponseSchema = new schema.Entity('goal_responses')
export const interactionSchema = new schema.Entity('interactions')
export const lifeDataSchema = new schema.Entity('life_datas')
export const threadSchema = new schema.Entity('threads')
export const userAccessorSchema = new schema.Entity('user_accessors')

export function autoYields (data: any): any[]{
    const yields = [] as any[]
    const pData = {
      global_settings: [globalSettingsSchema],
      interaction: interactionSchema,
      interactions: [interactionSchema],
      life_data: lifeDataSchema,
      life_datas: [lifeDataSchema],
      thread: threadSchema,
      threads: [threadSchema],
      meta: metaSchema,
      metas: [metaSchema],
      resource: resourceSchema,
      resources: [resourceSchema],
      role: roleSchema,
      roles: [roleSchema],
      user_role: userRoleSchema,
      user_roles: [userRoleSchema],
      user: userSchema,
      users: [userSchema],
      user_accessor: userAccessorSchema,
      user_accessors: [userAccessorSchema],
      my_user: true // no schema only custom handle
    } as any

    Object.keys(data).forEach((property: string) => {
      if (pData[property]) {
        switch (property) {
          // TODO REVIEW IMPLEMENTATION
          case 'my_user':
            localStorage.setItem("user", JSON.stringify(data[property]));
            yields.push(
              put({
                type: "REDUCE_" + property + "_SUCCESS",
                overrides: [
                 {
                  key: 'my_user',
                  value: data[property]
                 }
                ],
              })
            );
            break;
          case 'global_settings':
            yields.push(
              put({
                type: "REDUCE_" + property + "_SUCCESS",
                overrides: [
                 {
                  key: 'global_settings',
                  value: data[property]
                 },
                 {
                  key: 'theme',
                  value: {
                    colors: getThemeColors(data[property].theme),
                    classes: getThemeClasses(data[property].theme)
                  }
                 }
                ],
              })
            );
            localStorage.setItem(
              "global_settings",
              JSON.stringify(data[property])
            );
    
            break;
          default:
            yields.push(
              put({
                type: "REDUCE_" + property + "_SUCCESS",
                normalized: normalize(data[property], pData[property]),
              })
            );
        }
      }
      else if (property.startsWith('deleted_')){ // check for deleted keys
        const compareString = property.replace('deleted_', '')
        if (pData[compareString]) {
          let deleteIds = data[property];
          let stateKey = '';
          let idAttribute = '';
          if (!isArray(deleteIds)){
            deleteIds = [deleteIds]
          }
          if (isArray(pData[compareString])){
            stateKey = pData[compareString][0]._key
            idAttribute = pData[compareString][0]._idAttribute
          }
          else{
            stateKey = pData[compareString]._key
            idAttribute = pData[compareString]._idAttribute
          }
          deleteIds = deleteIds.map((obj: any) => obj[idAttribute])
          const removeKeys = [{ entities: stateKey, ids: deleteIds }];
          yields.push(put({ type: 'REDUCE_DELETE_' + property + '_SUCCESS', removeKeys, forceStateRefresh: true}));
        }
      }
    });
    return yields
}